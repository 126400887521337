import { Button, Flex } from "@mantine/core";
import {
  IconTrash,
  IconRotateClockwise,
  IconRotate,
  IconCrop,
  IconFlipHorizontal,
  IconFlipVertical,
} from "@tabler/icons-react";
import {
  getInvertedImage,
  getRotatedImage,
} from "../cropper/helper/createCroppedImage";

export const ImageSectionMenu = ({
  isVerticalOrientation,
  rotation,
  setRotation,
  setCroppedImage,
  setFile,
  setAppBarState,
  croppedImage,
}: {
  isVerticalOrientation: boolean;
  rotation: number;
  setRotation: any;
  setCroppedImage: any;
  setFile: any;
  setAppBarState: any;
  croppedImage: string;
}) => {
  return (
    <Flex
      direction={isVerticalOrientation ? "column" : "row"}
      gap={10}
      style={{ position: "absolute", flexWrap: "wrap" }}
    >
      <Button
        size="sm"
        p={0}
        m={0}
        color="dark.3"
        onClick={() => {
          setCroppedImage("");
          setAppBarState({ isEditing: true });
          console.log("called", setAppBarState);
        }}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <IconCrop size={30} />
      </Button>
      <Button
        size="sm"
        p={0}
        m={0}
        color="blue.4"
        onClick={async () => {
          const image = await getRotatedImage(
            croppedImage,
            (rotation - 90) % 360
          );
          setCroppedImage(image);
        }}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <IconRotateClockwise />
      </Button>
      <Button
        size="sm"
        p={0}
        m={0}
        color="blue.4"
        onClick={async () => {
          const image = await getRotatedImage(
            croppedImage,
            (rotation + 90) % 360
          );
          setCroppedImage(image);
        }}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <IconRotate />
      </Button>
      <Button
        size="sm"
        p={0}
        m={0}
        color="red.5"
        onClick={() => {
          setCroppedImage("");
          setFile("");
          setAppBarState({ isEditing: false });
        }}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <IconTrash />
      </Button>
      <Button
        size="sm"
        p={0}
        m={0}
        color="indigo.4"
        onClick={async () => {
          const image = await getInvertedImage(croppedImage, {
            horizontal: true,
            vertical: false,
          });
          setCroppedImage(image);
        }}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <IconFlipHorizontal />
      </Button>
      <Button
        size="sm"
        p={0}
        m={0}
        color="indigo.4"
        onClick={async () => {
          const image = await getInvertedImage(croppedImage, {
            horizontal: false,
            vertical: true,
          });
          setCroppedImage(image);
        }}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <IconFlipVertical />
      </Button>
    </Flex>
  );
};
