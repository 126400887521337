import {
  Button,
  FileButton,
  Flex,
  Group,
  Image,
  Overlay,
  useMantineTheme,
} from "@mantine/core";
import { useRef, useState } from "react";
import { ImageCropper } from "../cropper/Cropper";
import { ImageSectionMenu } from "./ImageSectionMenu";

export const ImageSection = ({
  isVerticalOrientation,
  setAppBarState,
  index,
}: {
  isVerticalOrientation: boolean;
  setAppBarState: any;
  index: number;
}) => {
  const [file, setFile] = useState<string>("");
  const [rotation, setRotation] = useState<number>(0);
  const theme = useMantineTheme();

  const [croppedImage, setCroppedImage] = useState<string>("");
  const resetRef = useRef<() => void>(null);
  const boundary = index ? "left" : "right";

  return (
    <>
      <Group
        position={!isVerticalOrientation ? boundary : "center"}
        align={!isVerticalOrientation ? "center" : "start"}
        h={!isVerticalOrientation ? "100vh" : "50vh"}
        w={!isVerticalOrientation ? "50vw" : "100vw"}
        style={{
          border: `1px solid ${theme.colors.dark[2]}`,
          background: theme.colors.dark[5],
          overflow: "hidden",
        }}
      >
        <ShowIf
          condition={!!croppedImage}
          show={
            <Flex
              direction={isVerticalOrientation ? "column" : "row"}
              align={"left"}
              justify={"center"}
            >
              <Image
                display={"flex"}
                src={croppedImage}
                alt="Picked image"
                fit="cover"
                style={{
                  overflow: "hidden",
                  maxHeight: isVerticalOrientation ? "50vh" : "100vh",
                  maxWidth: isVerticalOrientation ? "375px" : "none",
                }}
              />
              <ImageSectionMenu
                setCroppedImage={setCroppedImage}
                setFile={setFile}
                setAppBarState={setAppBarState}
                setRotation={setRotation}
                croppedImage={croppedImage}
                rotation={rotation}
                isVerticalOrientation={isVerticalOrientation}
              />
            </Flex>
          }
        />

        <ShowIf
          condition={!file && !croppedImage}
          show={
            <FileButton
              resetRef={resetRef}
              onChange={(file: any) => {
                setFile(URL.createObjectURL(file));
                setAppBarState({ isEditing: true });
              }}
              accept="image/png,image/jpeg"
            >
              {(props) => <Button {...props}>Upload image</Button>}
            </FileButton>
          }
        />
      </Group>
      <ShowIf
        condition={!!file && !croppedImage}
        show={
          <Overlay opacity={1}>
            <ImageCropper
              rotation={rotation}
              setRotation={setRotation}
              file={file}
              setFile={setFile}
              setCroppedImage={setCroppedImage}
              setAppBarState={setAppBarState}
            />
          </Overlay>
        }
      />
    </>
  );
};

export const ShowIf = ({
  condition,
  show,
}: {
  condition: boolean;
  show: JSX.Element;
}) => {
  return condition && show ? show : null;
};
