import { Box, Flex } from "@mantine/core";
import "./App.css";
import { ImageSection } from "./components/imageSection/ImageSection";
import { useState } from "react";
import { AppMenu } from "./components/appMenu/AppMenu";

function App() {
  const [appBarState, setAppBarState] = useState<{
    leftImage: {
      isEditing: boolean;
    };
    rightImage: {
      isEditing: boolean;
    };
  }>({
    leftImage: {
      isEditing: false,
    },
    rightImage: {
      isEditing: false,
    },
  });
  const [isVerticalOrientation, setIsVerticalOrientation] =
    useState<boolean>(true);
  const hasAppBar =
    !appBarState?.leftImage?.isEditing && !appBarState?.rightImage?.isEditing;

  return (
    <>
      <Box
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <Flex direction={isVerticalOrientation ? "column" : "row"}>
          <ImageSection
            setAppBarState={({ isEditing }: { isEditing: boolean }) =>
              setAppBarState((prev) => ({
                ...prev,
                leftImage: {
                  isEditing,
                },
              }))
            }
            isVerticalOrientation={isVerticalOrientation}
            index={0}
          />
          <ImageSection
            setAppBarState={({ isEditing }: { isEditing: boolean }) =>
              setAppBarState((prev) => ({
                ...prev,
                rightImage: {
                  isEditing,
                },
              }))
            }
            isVerticalOrientation={isVerticalOrientation}
            index={1}
          />
        </Flex>
      </Box>
      <AppMenu
        hasAppBar={hasAppBar}
        setIsVerticalOrientation={setIsVerticalOrientation}
      />
    </>
  );
}

export default App;
