import { Button, useMantineTheme } from "@mantine/core";
import { ShowIf } from "../imageSection/ImageSection";
import { IconArrowsDownUp, IconArrowsLeftRight } from "@tabler/icons-react";

export const AppMenu = ({
  setIsVerticalOrientation,
  hasAppBar,
}: {
  setIsVerticalOrientation: any;
  hasAppBar: boolean;
}) => {
  const theme = useMantineTheme();
  return (
    <ShowIf
      condition={hasAppBar}
      show={
        <Button
          fullWidth
          onClick={() => setIsVerticalOrientation((prev: boolean) => !prev)}
          rightIcon={<IconArrowsLeftRight />}
          leftIcon={<IconArrowsDownUp />}
          gradient={{ from: "indigo.7", to: "red.4", deg: 99 }}
        >
          Toggle Orientation
        </Button>
      }
    />
  );
};
