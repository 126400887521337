import { useState } from "react";
import Cropper from "react-easy-crop";
import {
  IconTrash,
  IconRotateClockwise,
  IconRotate,
} from "@tabler/icons-react";
import "./styles.css";
import {
  Box,
  Button,
  Flex,
  Group,
  Slider,
  useMantineTheme,
} from "@mantine/core";
import { getCroppedImg } from "./helper/createCroppedImage";
import { CropperMenu } from "./CropperMenu";

export const ImageCropper = ({
  file,
  setCroppedImage,
  setFile,
  setAppBarState,
  rotation,
  setRotation,
}: {
  file: string;
  setCroppedImage: any;
  setFile: any;
  setAppBarState: any;

  rotation: number;
  setRotation: any;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const theme = useMantineTheme();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [zoom, setZoom] = useState(1);
  const onCropComplete = (_: unknown, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        file,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      setAppBarState({ isEditing: false });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Cropper
        image={file} //"https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
        crop={crop}
        zoom={zoom}
        aspect={1}
        rotation={rotation}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        style={{
          containerStyle: {
            maxWidth: "500px",
            maxHeight: "500px",
            margin: "0 auto",
            display: "flex",
          },
        }}
      />
      <Box
        p="lg"
        style={{
          maxWidth: "500px",
          margin: "77vh auto 0 auto",
          backgroundColor: theme.colors.dark[4],
        }}
      >
        <Flex
          align="center"
          direction={"column"}
          gap={50}
          // style={{
          //   backgroundColor: "white",
          // }}
        >
          <Slider
            value={zoom}
            aria-labelledby="Zoom"
            onChange={setZoom}
            className="zoom-range"
            min={1}
            max={3}
            marks={[
              { value: 1, label: "1x" },
              { value: 2, label: "2x" },
              { value: 3, label: "3x" },
            ]}
            style={{
              border: "none",
            }}
          />
          <CropperMenu showCroppedImage={showCroppedImage} />
        </Flex>
      </Box>
    </>
  );
};
