import { Button, Group } from "@mantine/core";

export const CropperMenu = ({
  showCroppedImage,
}: {
  showCroppedImage: any;
}) => {
  return (
    <Group>
      <Button
        variant="gradient"
        onClick={showCroppedImage}
        gradient={{ from: "indigo.7", to: "red.4", deg: 99 }}
      >
        Use Cropped Image
      </Button>
    </Group>
  );
};
